@keyframes chartjs-render-animation {
  0% {
    opacity: .99; }
  100% {
    opacity: 1; } }

.chartjs {
  &-render-monitor {
    animation: chartjs-render-animation 1ms; }

  &-size-monitor {
    &, &-expand, &-shrink {
      position: absolute;
      direction: ltr;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      pointer-events: none;
      visibility: hidden;
      z-index: -1; }

    &-expand {
      > div {
        position: absolute;
        width: 1000000px;
        height: 1000000px;
        left: 0;
        top: 0; } }

    &-shrink {
      > div {
        position: absolute;
        width: 200%;
        height: 200%;
        left: 0;
        top: 0; } } } }
