
html {
  --color-mode: "light";
  --light: #fff;
  --dark:  rgb(28,28,30);
  --haze: #f2f5f7;
  --bubble: rgb(36,36,38);
  --accent: var(--haze);
  --bg: var(--light);
  --code-bg: var(--accent);
  --overlay: var(--light);
  //--text: #111
  --text: #141010;
  --font: 'Metropolis', sans-serif;
  --border-color: #eee;
  --inline-color: darkgoldenrod;
  --theme: rgb(52,199,89);
  --ease: ease;
  --scroll-thumb: rgba(0,0,0,.06);
  --search-border-color: transparent;
  --next-icon-path: url(../images/icons/double-arrow.svg);
  --never-icon-path: url(../images/sitting.svg);

  @mixin darkmode {
    --color-mode: "dark";
    --theme: rgb(48,209,88);
    --bg: var(--dark);
    --text: #eee;
    --text-light: #fff;
    --accent: var(--bubble);
    --overlay: var(--bubble);
    --border-color: transparent;
    --scroll-thumb: rgba(255,255,255,.06);
    --search-bg: var(--accent);
    --search-border-color: var(--accent);
    * {
      box-shadow: none !important; } }

  &[data-mode="dark"] {
    @include darkmode;
    .color {
      &_choice {
        &::after {
          background-image: var(--moon-icon); } } } }

  &[data-mode="auto"] {
    @media (prefers-color-scheme: dark) {
      @include darkmode; } } }

%narrow {
  max-width: 750px;
  margin: 0 auto; }

blockquote {
  + .highlight_wrap {
    margin-top: 2.25rem; } }
