@mixin shadow($opacity: 0.17) {
  box-shadow: 0 0 3rem rgba(0,0,0,$opacity);
  &:hover {
    box-shadow: 0 0 5rem rgba(0,0,0, (1.5 * $opacity)); } }

.post {
  margin: 0 auto;
  width: 100%;
  p, h1, h2, h3, h4, h5, h6, blockquote, ol, ul, .highlight_wrap, hr {
    max-width: 840px !important;
    margin-left: auto;
    margin-right: auto; }

  img:not(.icon) {
    @media screen and (min-width: 1025px) {
      display: block;
      width: 100vw;
      max-width: 1024px;
      margin-left: auto;
      margin-right: auto; } }

  h2,h3,h4 {
    margin: 0.5rem auto;
    text-align: left;
    padding: 5px 0 0 0; }

  p {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    font-size: 1.05rem; }

  &s {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    width: 100%;
    align-items: stretch; }

  &s:not(.aside) {
    padding: 0 30px; }

  ol {
    padding: 1rem 1.25rem; }

  &_body {
    img {
      width: 100%;
      max-width: 100%; } }
  &_inner {
    a {
      color: var(--theme);
      transition: all 0.3s;
      &:hover {
        opacity: 0.8;
        text-decoration: underline; } }

    img:not(.icon) {
      margin-bottom: 2rem;
      box-shadow: 0 1.5rem 1rem -1rem rgba(0,0,0,0.25);
      ~ h1, ~ h2, ~ h3, ~ h4 {
        margin-top: 0;
        padding-top: 0; } } }

  .icon {
    margin-top: 0;
    margin-bottom: 0; }

  &_date {
    color: var(--theme); }

  &_copy {
    opacity: 0;
    transition: opacity 0.3s ease-out; }

  &_item {
    @include shadow;
    margin: 1.25rem 0;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    @media screen and (min-width:667px) {
      width: 47%; } }

  &_item:hover &_copy {
    opacity: 1; }

  &_link {
    padding: 2.5px 0;
    font-size: 1.25em;
    margin: 2.5px 0;
    text-align: left; }

  &_meta {
    overflow: hidden;
    opacity: 0.8;
    font-size: 0.84rem;
    font-weight: 500;
    display: inline-grid;
    grid-template-columns: auto 1fr;
    background-color: var(--light);
    padding: 0;
    align-items: center;
    border-radius: 0.3rem;
    color: var(--dark);
    text-transform: capitalize;
    a {
      &:hover {
        color: var(--theme);
        text-decoration: underline;
        opacity: 0.9; } } }

  &_extra {
    display: flex;
    justify-content: flex-end; }

  &_tag {
    font-size: 0.75rem !important;
    font-weight: 500;
    background: var(--theme);
    color: var(--light);
    padding: 0.25rem 0.67rem !important;
    text-transform: uppercase;
    display: inline-flex;
    border-radius: 5px; }

  &_title {
    margin: 1.75rem 0 1rem; }

  &_time {
    background: var(--theme);
    display: inline-grid;
    padding: 0.2rem 0.75rem;
    color: var(--light); }

  &_thumbnail {
    width: 100%;
    margin: 0; }

  &_nav {
    padding: 3rem 1.5rem;
    display: grid;
    margin: 2.25rem auto 1rem;
    text-align: center;
    color: var(--theme);
    // box-shadow: 0 1rem 3rem -1rem rgba(0,0,0,0.15)
    text-transform: uppercase;
    &, span {
      position: relative;
      z-index: 3; }

    &::before {
      content: "";
      position: absolute;
      background: var(--accent);
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      border-radius: 1rem; } }

  &_next {
    display: inline-grid;
    margin: 0 auto;
    width: 10rem;
    grid-template-columns: 1fr 1.33rem;
    &::after {
      content: "";
      background-image: var(--next-icon-path);
      background-repeat: repeat no-repeat;
      background-size: 0.8rem;
      background-position: center right; } } }

// .pager
//   display: grid
//   grid-template-columns: 2.5rem 1fr 2.5rem
//   margin: 2rem auto 0
//   max-width: 12.5rem
//   &, &_item
//     justify-content: center
//     align-items: center

//   &_item
//     height: 2.5rem
//     width: 2.5rem
//     display: inline-flex
//     margin-left: 5px
//     margin-right: 5px
//     background-color: var(--accent)
//     color: var(--light)
//     border-radius: 50%
//     &:hover
//       opacity: 0.5

//   span
//     text-align: center

.excerpt {
  padding: 0 10px 1.5rem 10px;
  position: relative;
  z-index: 1;
  &_meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transform: translateY(-2.5rem);
    position: relative;
    z-index: 5; } }

.archive {
  &_item {
    display: grid;
    padding: 1.5rem 0; }

  &_title {
    margin: 0; } }

.article {
  box-shadow: 0 0.5rem 2rem rgba(0,0,0,0.12);
  overflow: hidden;
  border-radius: 0.5rem;
  &_title {
    margin: 0; }
  &_excerpt {
    &:not(.visible) {
      height: 0;
      opacity: 0; }
    transition: height 0.5s, opacity 0.5s; }
  &_excerpt,
  &_meta {
    transform-origin: bottom; }
  &_meta {
    padding: 10px 1.25rem 1.25rem;
    color: var(--text);
    position: relative;
    z-index: 2;
    transition: margin-top 0.5s;
    background: var(--bg);
    &.center_y {
      transform-origin: center;
      transition: transform 0.5s;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media screen and (min-width: 42rem) {
        left: -2rem; } } }

  &_thumb {
    display: grid;
    position: relative;
    z-index: 0;
    overflow: hidden;
    height: 15rem;
    background-size: cover;
    background-position: 50% 50%;
    @media screen and (min-width: 35rem) {
      height: 22.5rem; }

    img {
      transition: transform 0.5s, opacity 0.5s; }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      bottom: 0;
      z-index: 1;
      background: var(--bg);
      opacity: 0;
      transition: opacity 0.1s ease-out; } }

  &_showcase &_thumb {
    height: 15rem; }

  &_showcase &_meta {
    padding-top: 1.5rem; }

  &:hover &_thumb {
    img {
      transform: scale(1.1); }

    &::after {
      transition: opacity 0.1s ease-out;
      opacity: 0.5; } }

  &:hover &_excerpt:not(.visible) {
    height: 75px;
    opacity: 1; }

  &:hover &_meta {
    &:not(.center_y) {
      margin-top: -75px; }

    @media screen and (min-width: 769px) {
      &.center_y {
        transform: translateX(-3rem); } } }

  &:hover {
    box-shadow: 0 1.5rem 6rem rgba(0,0,0,0.17);
    a {
      color: initial !important; } }

  &_hidden {
    display: none; } }
