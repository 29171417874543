$font-path: "../fonts";
@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 400;
  src: local('Metropolis Regular'), local('Metropolis-Regular'), url('#{$font-path}/Metropolis-Regular.woff2') format('woff2'), url('#{$font-path}/Metropolis-Regular.woff') format('woff');
  font-display: swap; }

@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 300;
  src: local('Metropolis Light'), local('Metropolis-Light'), url('#{$font-path}/Metropolis-Light.woff2') format('woff2'), url('#{$font-path}/Metropolis-Light.woff') format('woff');
  font-display: swap; }

@font-face {
  font-family: 'Metropolis';
  font-style: italic;
  font-weight: 300;
  src: local('Metropolis Light Italic'), local('Metropolis-LightItalic'), url('#{$font-path}/Metropolis-LightItalic.woff2') format('woff2'), url('#{$font-path}/Metropolis-LightItalic.woff') format('woff');
  font-display: swap; }

@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 500;
  src: local('Metropolis Medium'), local('Metropolis-Medium'), url('#{$font-path}/Metropolis-Medium.woff2') format('woff2'), url('#{$font-path}/Metropolis-Medium.woff') format('woff');
  font-display: swap; }

@font-face {
  font-family: 'Metropolis';
  font-style: italic;
  font-weight: 500;
  src: local('Metropolis Medium Italic'), local('Metropolis-MediumItalic'), url('#{$font-path}/Metropolis-MediumItalic.woff2') format('woff2'), url('#{$font-path}/Metropolis-MediumItalic.woff') format('woff');
  font-display: swap; }

@font-face {
  font-family: 'Cookie';
  font-style: normal;
  font-weight: 400;
  src: local('Cookie-Regular'), url('#{$font-path}/cookie-v10-latin-regular.woff2') format('woff2'), url('#{$font-path}/cookie-v10-latin-regular.woff') format('woff');
  font-display: swap; }
